import { AxiosResponse } from 'axios';
import { IFetchBestPagesOptions } from 'common/interfaces/pages-api';
import { IFetchProductOptions } from 'common/interfaces/products-api';

import { IBrand, IPublisher } from '../../context/pages-context';
import { Marketplaces } from '../constants/enums';
import { AmazonProduct, WalmartProduct } from '../interfaces/agg-page';
import { ApiService } from './api.service';

export interface PublisherListResponseType {
  publishers: IPublisher[];
  totalCount: number;
}

export interface BrandListResponseType {
  data: {
    totalCount: number;
    featuredBrands: IBrand[];
  };
}

class ProductsApi extends ApiService {
  constructor(host: string, publisher: string) {
    super(host, publisher);
    this.getBrandAttributionPage = this.getBrandAttributionPage.bind(this);
  }

  getProductByAsin(
    asin: string,
    options: Partial<IFetchProductOptions> = {},
  ): Promise<
    AxiosResponse<{
      data: { products: AmazonProduct[] };
    }>
    > {
    return this.get('/products', { params: { asins: asin, no_cache: 1, ...options } });
  }

  getProductById(
    w_item_id: string,
    options: Partial<IFetchProductOptions> = {},
  ): Promise<AxiosResponse<{ products: WalmartProduct[] }>> {
    return this.get('/walmart/products', {
      params: { no_cache: 1, w_items_id: w_item_id, ...options },
    });
  }

  getFeaturedBrands(name: string): Promise<AxiosResponse<BrandListResponseType>> {
    return this.get('/products/featured-brands', { params: { forced: 1, limit: 100, name } });
  }

  getPublishersList(name?: string): Promise<AxiosResponse<PublisherListResponseType>> {
    return this.get('/publisher/list', { params: { name } });
  }

  getBestPages(
    slug: string,
    options: Partial<IFetchBestPagesOptions> = {},
  ): Promise<AxiosResponse> {
    return this.get('/pages/best', {
      params: {
        limit: 50,
        slug,
        ...options,
      },
    });
  }

  getBrandAttributionPage(
    data: {
      slug: string;
      brand?: string;
      marketplace: Marketplaces;
    },
    domain = '',
  ): Promise<AxiosResponse> {
    return this.get('/pages/brands/attribution', {
      headers: { 'x-picks-domain': domain },
      params: { ...data, no_cache: 1 },
    });
  }

  downloadCsvFile(slug: string, options: Partial<IFetchBestPagesOptions> = {}) {
    return this.get('/admin/download/agg-pages', {
      params: {
        limit: 50,
        no_cache: 1,
        slug,
        ...options,
        attribution: options.marketplace === Marketplaces.Walmart ? null : true,
      },
    });
  }
}

const _client: Record<string, ProductsApi> = {};

export function getProductsApiClient(publisher: string = 'productpicks'): ProductsApi {
  if (!_client[publisher]) {
    _client[publisher] = new ProductsApi(process.env.REACT_APP_API_HOST, publisher);
  }

  return _client[publisher];
}
