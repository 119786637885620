import { createAction } from '@reduxjs/toolkit';

import { ChangeOrderStatusData } from '../common/interfaces/order-api';
import { IOrderFilter } from '../modules/orders';
import { authorSlice } from './reducers/AuthorReducer';
import { actions as confirmModalActions } from './reducers/common/confirm-modal-slice';
import { actions as domainListActions } from './reducers/common/domain-list-slice';
import { actions as publisherImagesListActions } from './reducers/common/publisher-images-list-slice';
import {
  actions as getCompareProductTableActions
} from './reducers/compare-product-table/get-product-compare-table';
import {
  actions as updateCompareProductTableActions
} from './reducers/compare-product-table/update-product-compare-table';
import { actions as couponAttachingActions } from './reducers/coupons/couponAttachingReducer';
import { actions as createCouponActions } from './reducers/coupons/createCreateCouponReducer';
import { actions as getCouponsListActions } from './reducers/coupons/getCouponListReducer';
import { actions as getProductsCouponListActions } from './reducers/coupons/getProductsCouponListReducer';
import { actions as updateCouponAction } from './reducers/coupons/updateCouponReducer';
import { actions as dailyDealsActions } from './reducers/daily-deals/daily-deals';
import { orderSlice } from './reducers/OrderReducer';
import { actions as orderStatusActions } from './reducers/orders/orderStatusSlice';
import { actions as refundOrderActions } from './reducers/orders/refundOrderSlice';
import { actions as brandListActions } from './reducers/pages/brand-list-slice';
import { actions as deletePageActions } from './reducers/pages/delete-page-slice';
import { actions as pageStatusesListActions } from './reducers/pages/page-statuses-list-slice';
import { actions as pagesListActions } from './reducers/pages/pages-list-slice';
import { actions as publisherListActions } from './reducers/pages/publisher-list-slice';
import { actions as checkingKeywordActions } from './reducers/products/checkKeywordSlice';

const changeOrdersStatusFromDetails = createAction(
  'orderStatus/changeFromDetails',
  (data: ChangeOrderStatusData) => ({
    payload: {
      ...data,
    },
  }),
);

const changeOrdersStatusFromTable = createAction(
  'orderStatus/changeFromTable',
  (data: ChangeOrderStatusData, orderFilter: IOrderFilter) => ({
    payload: {
      data,
      orderFilter,
    },
  }),
);

export const actions = {
  ...orderSlice.actions,
  ...authorSlice.actions,
  ...orderStatusActions,
  ...refundOrderActions,
  ...checkingKeywordActions,
  ...deletePageActions,
  ...pagesListActions,
  ...publisherListActions,
  ...pageStatusesListActions,
  ...brandListActions,
  ...publisherImagesListActions,
  ...createCouponActions,
  ...getCouponsListActions,
  ...getProductsCouponListActions,
  ...couponAttachingActions,
  ...confirmModalActions,
  ...domainListActions,
  ...dailyDealsActions,
  ...updateCompareProductTableActions,
  ...getCompareProductTableActions,
  ...updateCouponAction,
  changeOrdersStatusFromDetails,
  changeOrdersStatusFromTable,
};
