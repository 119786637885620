import { AggPageComponent, FormMode } from 'common/constants/enums';
import { IBrand, PagesContext } from 'context/pages-context';
import { useContext, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';

import { IFetchBestPagesOptions } from '../../../../common/interfaces/pages-api';
import { FormItem } from '../../../../common/ui/formItem';
import { Select } from '../../../../common/ui/select';
import { TSelectOption } from '../../../../common/ui/select-old';
import { TSetFilter, useActions, useAppSelector } from '../../../../hooks';
import { BrandOption, BrandOptionType } from './brand-option';

interface IBrandInputProps {
  formMode: string;
  brandName?: string;
  updateFilter?: TSetFilter<Omit<IFetchBestPagesOptions, 'attribution'>>;
  label?: string;
  placeholder?: string;
  className?: string
}

export const BrandInput = ({
  brandName,
  className = '',
  formMode,
  label,
  placeholder,
  updateFilter
}: IBrandInputProps) => {
  const { brandList } = useAppSelector(({ pages }) => pages);
  const { fetchBrandListRequest } = useActions();
  const { brand, changeBrand, error } = useContext(PagesContext);
  const [brandOptions, setBrandOptions] = useState<BrandOptionType[]>([]);
  const [inputSearchValue, setInputSearchValue] = useState<string>('');

  const disabled = formMode === FormMode.UPDATE;
  const value = brandOptions.find(
    (elem) => elem.value === brandName || elem.value === brand?.name,
  );

  const clickHandler = (brand: IBrand) => {
    changeBrand(brand);
    if (updateFilter) updateFilter({ brand: brand?.name });
  };

  const onChangeHandler = (option: OnChangeValue<TSelectOption, false>) => {
    if (option) {
      clickHandler(brandList.brands.find((elem) => elem.name === option.value));
    } else {
      clickHandler(null);
    }
  };

  useEffect(() => {
    setBrandOptions(
      brandList.brands.map((elem) => ({ label: elem?.name, value: elem?.name, ...elem })),
    );
  }, [brandList.brands]);

  useEffect(() => {
    if (brand?.name) {
      fetchBrandListRequest(brand?.name);
    } else {
      fetchBrandListRequest(brandName || inputSearchValue);
    }
  }, [inputSearchValue, brand, brandName]);

  return (
    <FormItem className={className} label={label} message={error[AggPageComponent.Brand]}>
      <Select
        components={{ Option: BrandOption }}
        inputValue={inputSearchValue}
        isClearable
        isDisabled={disabled}
        isLoading={brandList.loading}
        isSearchable
        onChange={onChangeHandler}
        onInputChange={setInputSearchValue}
        options={brandOptions}
        placeholder={placeholder}
        value={value}
      />
    </FormItem>
  );
};
