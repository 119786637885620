import importImage from '../../../../assets/images/link.png';
import { TemporaryLinksForm } from '../../components';
import styles from './temporary-links-page.module.scss';

const TemporaryLinksPage = () => <div className={styles.tag_creator}>
  <img alt="product-import-img" className={styles.logo} src={importImage} />
  <h1 className={styles.title}>Hardcode tags</h1>
  <span className={styles.hint}>Hardcode the tags below</span>
  <TemporaryLinksForm />
</div>;

export { TemporaryLinksPage };
