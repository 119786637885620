import { ApiService } from './api.service';

export interface CreateTemporaryLinkBody {
  expired_at: string
  publisher_id?: number
  product_links: Array<{ asin: string, link: string }>
}

class TemporaryLinkServiceApi extends ApiService {
  createLink = (data: CreateTemporaryLinkBody) => this.post('/admin/products/temporary-links', data);

  downloadCsvFile = () => this.get('/admin/download/temporary-links');
}

export const TemporaryLinkService = new TemporaryLinkServiceApi(process.env.REACT_APP_API_HOST);
