import clsx from 'clsx';
import { OptionProps } from 'react-select';

import { Image } from '../../../../common/ui/image';
import { IBrand } from '../../../../context/pages-context';
import styles from './brand-input.module.scss';

export interface BrandOptionType extends IBrand {
  label: string;
  value: string;
}

const BrandOption = ({ data, innerProps, isSelected }: OptionProps<BrandOptionType, false>) => (
  <div {...innerProps} className={clsx(styles.option, isSelected && styles.option__selected)}>
    <div className={clsx(!data.img_logo && styles.image_wrapper__empty)}>
      {data.img_logo && (
        <Image
          alt="brand_logo"
          className={styles.image_wrapper}
          height={30}
          src={data.img_logo}
          width={30}
        />
      )}
      {!data.img_logo && <span className={styles.abbreviature}>{data.label[0]}</span>}
    </div>
    <span>{data.label}</span>
  </div>
);

export { BrandOption };
