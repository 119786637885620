import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { authorReducer } from './reducers/AuthorReducer';
import { reducer as confirmModalReducer } from './reducers/common/confirm-modal-slice';
import { reducer as domainListReducer } from './reducers/common/domain-list-slice';
import { reducer as publisherImagesListReducer } from './reducers/common/publisher-images-list-slice';
import {
  reducer as getCompareProductTableReducer
} from './reducers/compare-product-table/get-product-compare-table';
import {
  reducer as updateCompareProductTableReducer
} from './reducers/compare-product-table/update-product-compare-table';
import { reducer as couponAttachingReducer } from './reducers/coupons/couponAttachingReducer';
import { reducer as createCouponReducer } from './reducers/coupons/createCreateCouponReducer';
import { reducer as getCouponsListReducer } from './reducers/coupons/getCouponListReducer';
import { reducer as getProductsCouponListReducer } from './reducers/coupons/getProductsCouponListReducer';
import { reducer as updateCouponReducer } from './reducers/coupons/updateCouponReducer';
import { reducer as dailyDealsReducer } from './reducers/daily-deals/daily-deals';
import { orderReducer } from './reducers/OrderReducer';
import { reducer as orderStatusReducer } from './reducers/orders/orderStatusSlice';
import { reducer as refundOrderReducer } from './reducers/orders/refundOrderSlice';
import { reducer as brandListReducer } from './reducers/pages/brand-list-slice';
import { reducer as deletePageReducer } from './reducers/pages/delete-page-slice';
import { reducer as pageStatusesListReducer } from './reducers/pages/page-statuses-list-slice';
import { reducer as pagesListReducer } from './reducers/pages/pages-list-slice';
import { reducer as publisherListReducer } from './reducers/pages/publisher-list-slice';
import { reducer as checkingKeywordReducer } from './reducers/products/checkKeywordSlice';
import rootSaga from './sagas/root-saga';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  authorReducer,
  common: combineReducers({
    confirmModal: confirmModalReducer,
    domains: combineReducers({
      domainList: domainListReducer
    })
  }),
  compareProductTable: combineReducers({
    getCompareProductTable: getCompareProductTableReducer,
    updateCompareProductTable: updateCompareProductTableReducer
  }),
  couponsReducer: combineReducers({
    couponAttaching: couponAttachingReducer,
    createCoupon: createCouponReducer,
    getList: getCouponsListReducer,
    getProductsCouponList: getProductsCouponListReducer,
    updateCoupon: updateCouponReducer,
  }),
  dailyDeals: dailyDealsReducer,
  orderReducer,
  orderStatus: orderStatusReducer,
  pages: combineReducers({
    brandList: brandListReducer,
    deletePage: deletePageReducer,
    pageStatusesList: pageStatusesListReducer,
    pagesList: pagesListReducer,
    publisherList: publisherListReducer
  }),
  products: combineReducers({
    checkingKeyword: checkingKeywordReducer
  }),
  publisherImagesList: publisherImagesListReducer,
  refundOrder: refundOrderReducer
});

export const store = configureStore({
  middleware: [sagaMiddleware],
  reducer: rootReducer
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
